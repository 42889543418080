<template>
    <b-card class="card-custom">
        <p class="h5">Serveur de Paris</p>
        <p>Pour faciliter l'accès au serveur de Paris, veuillez cliquer sur le bouton ci-dessous, ce qui déclenchera le téléchargement d'un fichier. Une fois ce téléchargement terminé, vous pourrez simplement l'ouvrir, ce qui vous permettra d'établir automatiquement une connexion au serveur de Paris, Il ne reste plus que votre mot de passe a saisir.</p>
        <p class="font-weight-bold">Il est important de noter qu'à la fin de votre session, nous vous recommandons vivement de vous déconnecter. Pour ce faire, veuillez cliquer sur votre nom en haut à droite, puis sélectionner l'option "Log out". Cette action vous permettra de vous déconnecter immédiatement.</p>        
        <div class="text-center">
            <b-button variant="primary" :class="load ? 'btn-icon' : ''" @click="rdp()">
                <span v-if="!load">Telecharger</span>
                <i v-else class="fas fa-spin fa-spinner"></i>
            </b-button>            
        </div>        
        <b-row class="justify-content-center mt-3" v-if="password">
            <b-col md="4" class="bg-light-dark pt-3 rounded">
                <p>Votre mot de passe: <strong>{{ password }}</strong></p>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>    
    export default {
        name: "RDP", 
        data(){
            return {
                load:false,
                password: false
            }
        },
        components: {            
        }, 
        beforeMount() {            
        },
        methods:{
            rdp(){
                if(this.load === true){
                    return false;
                }
                this.load = true;
                this.$api.ajax('/user/rdp', null, res => {
                    if(res.status){
                        var hiddenElement = document.createElement('a');
                        hiddenElement.href = 'data:text/plain;charset=utf-8,' + encodeURI(res.data.file);
                        hiddenElement.target = '_blank';
                        hiddenElement.download = 'Paris.rdp';
                        hiddenElement.click();
                        this.password = res.data.password
                    }
                    this.load = false
                })
            }
        }
    };
</script>
<style></style>